import { registerLocaleData } from '@angular/common';
import LocaleIt from '@angular/common/locales/it';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoggedUserService } from '@core/service/logged-user.service';
import { FlamingoModule } from '@flamingo/flamingo.module';
import { FlamingoAuthService } from '@flamingo/service/flamingo-auth.service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp } from './app.startup';
import { AuthService } from './children/auth/auth.service';
import { AppConfigurationService } from '@x/service/app-configuration.service'
import { AppConfigurationServiceImpl } from './app-configuration-app.service'

registerLocaleData(LocaleIt, 'it');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    FlamingoModule.forRoot({ storagePrefix: 'esg-app-', baseUrl: environment.baseUrl }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    // Mat for admin form into dialog
    MatSelectModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [FlamingoAuthService, LoggedUserService],
    },
    {
      provide: LOCALE_ID,
      useValue: 'it-IT',
    },
    AuthService,
    {
      provide: AppConfigurationService,
      useClass: AppConfigurationServiceImpl,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
